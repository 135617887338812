//Wallet Connector
export const SET_WALLET_DETAILS = "SET_WALLET_DETAILS";

export const AMOUNT_CONVERSION_START = "AMOUNT_CONVERSION_START";
export const AMOUNT_CONVERSION_SUCCESS = "AMOUNT_CONVERSION_SUCCESS";
export const AMOUNT_CONVERSION_FAILURE = "AMOUNT_CONVERSION_FAILURE";

export const GENERATE_STRIPE_PAYMENT_START = "GENERATE_STRIPE_PAYMENT_START";
export const GENERATE_STRIPE_PAYMENT_SUCCESS =
  "GENERATE_STRIPE_PAYMENT_SUCCESS";
export const GENERATE_STRIPE_PAYMENT_FAILURE =
  "GENERATE_STRIPE_PAYMENT_FAILURE";

export const BUY_TOKEN_START = "BUY_TOKEN_START";
export const BUY_TOKEN_SUCCESS = "BUY_TOKEN_SUCCESS";
export const BUY_TOKEN_FAILURE = "BUY_TOKEN_FAILURE";

export const CREATE_KYC_APPLICANT_START = "CREATE_KYC_APPLICANT_START";
export const CREATE_KYC_APPLICANT_SUCCESS = "CREATE_KYC_APPLICANT_SUCCESS";
export const CREATE_KYC_APPLICANT_FAILURE = "CREATE_KYC_APPLICANT_FAILURE";

export const SWAP_TOKEN_START = "SWAP_TOKEN_START";
export const SWAP_TOKEN_SUCCESS = "SWAP_TOKEN_SUCCESS";
export const SWAP_TOKEN_FAILURE = "SWAP_TOKEN_FAILURE";

export const SET_BUY_DATA = "SET_BUY_DATA";
export const RESET_BUY_DATA = "RESET_BUY_DATA";
export const SET_SWAP_DATA = "SET_SWAP_DATA";
export const RESET_SWAP_DATA = "RESET_SWAP_DATA";

export const TRANSATIONS_LIST_START = "TRANSATIONS_LIST_START";
export const FETCH_MORE_TRANSATIONS = "FETCH_MORE_TRANSATIONS";
export const TRANSATIONS_LIST_SUCCESS = "TRANSATIONS_LIST_SUCCESS";
export const TRANSATIONS_LIST_FAILURE = "TRANSATIONS_LIST_FAILURE";

export const FETCH_STATIC_PAGE_START = "FETCH_STATIC_PAGE_START";
export const FETCH_STATIC_PAGE_SUCCESS = "FETCH_STATIC_PAGE_SUCCESS";
export const FETCH_STATIC_PAGE_FAILURE = "FETCH_STATIC_PAGE_FAILURE";

// FAQ Lists
export const FETCH_FAQS_LIST_START = "FETCH_FAQS_LIST_START";
export const FETCH_FAQS_LIST_SUCCESS = "FETCH_FAQS_LIST_SUCCESS";
export const FETCH_FAQS_LIST_FAILURE = "FETCH_FAQS_LIST_FAILURE";

// Auth
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

//Profile
export const PROFILE_START = "PROFILE_START";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

// Kyc update

export const UPDATE_KYC_START = "UPDATE_KYC_START";
export const UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS";
export const UPDATE_KYC_FAILURE = "UPDATE_KYC_FAILURE";

export const STYLOPAY_PAYMENT_START = "STYLOPAY_PAYMENT_START";
export const STYLOPAY_PAYMENT_SUCCESS = "STYLOPAY_PAYMENT_SUCCESS";
export const STYLOPAY_PAYMENT_FAILURE = "STYLOPAY_PAYMENT_FAILURE";

// Bank Accounts

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const VIEW_BANK_ACCOUNT_START = "VIEW_BANK_ACCOUNT_START";
export const VIEW_BANK_ACCOUNT_SUCCESS = "VIEW_BANK_ACCOUNT_SUCCESS";
export const VIEW_BANK_ACCOUNT_FAILURE = "VIEW_BANK_ACCOUNT_FAILURE";

// New Buy Flow
export const GET_ADMIN_BANK_ACCOUNT_START = "GET_ADMIN_BANK_ACCOUNT_START";
export const GET_ADMIN_BANK_ACCOUNT_SUCCESS = "GET_ADMIN_BANK_ACCOUNT_SUCCESS";
export const GET_ADMIN_BANK_ACCOUNT_FAILURE = "GET_ADMIN_BANK_ACCOUNT_FAILURE";

export const TOKEN_BUY_START = "TOKEN_BUY_START";
export const TOKEN_BUY_SUCCESS = "TOKEN_BUY_SUCCESS";
export const TOKEN_BUY_FAILURE = "TOKEN_BUY_FAILURE";

export const SET_TOKEN_BUY_DATA = "SET_TOKEN_BUY_DATA";
export const RESET_TOKEN_BUY_DATA = "RESET_TOKEN_BUY_DATA";

export const TOKEN_BUY_TRANSACTIONS_LIST_START =
  "TOKEN_BUY_TRANSACTIONS_LIST_START";
export const TOKEN_BUY_TRANSACTIONS_LIST_SUCCESS =
  "TOKEN_BUY_TRANSACTIONS_LIST_SUCCESS";
export const TOKEN_BUY_TRANSACTIONS_LIST_FAILURE =
  "TOKEN_BUY_TRANSACTIONS_LIST_FAILURE";
export const MORE_TOKEN_BUY_TRANSACTIONS_LIST_START =
  "MORE_TOKEN_BUY_TRANSACTIONS_LIST_START";

export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";
export const FETCH_MORE_ALL_TRANSACTION_START =
  "FETCH_MORE_ALL_TRANSACTION_START";

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";
export const FETCH_MORE_WITHDRAWALS_START = "FETCH_MORE_WITHDRAWALS_START";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const FETCH_SINGLE_WALLET_VIEW_START = "FETCH_SINGLE_WALLET_VIEW_START";
export const FETCH_SINGLE_WALLET_VIEW_SUCCESS =
  "FETCH_SINGLE_WALLET_VIEW_SUCCESS";
export const FETCH_SINGLE_WALLET_VIEW_FAILURE =
  "FETCH_SINGLE_WALLET_VIEW_FAILURE";

export const WITHDRAWAL_REQUEST_START = "WITHDRAWAL_REQUEST_START";
export const WITHDRAWAL_REQUEST_SUCCESS = "WITHDRAWAL_REQUEST_SUCCESS";
export const WITHDRAWAL_REQUEST_FAILURE = "WITHDRAWAL_REQUEST_FAILURE";

export const GET_ADMIN_CRYPTO_ACCOUNT_START = "GET_ADMIN_CRYPTO_ACCOUNT_START";
export const GET_ADMIN_CRYPTO_ACCOUNT_SUCCESS =
  "GET_ADMIN_CRYPTO_ACCOUNT_SUCCESS";
export const GET_ADMIN_CRYPTO_ACCOUNT_FAILURE =
  "GET_ADMIN_CRYPTO_ACCOUNT_FAILURE";

export const DEPOSIT_TOKEN_START = "DEPOSIT_TOKEN_START";
export const DEPOSIT_TOKEN_SUCCESS = "DEPOSIT_TOKEN_SUCCESS";
export const DEPOSIT_TOKEN_FAILURE = "DEPOSIT_TOKEN_FAILURE";

export const TOKEN_DEPOSIT_TRANSACTIONS_LIST_START =
  "TOKEN_DEPOSIT_TRANSACTIONS_LIST_START";
export const TOKEN_DEPOSIT_TRANSACTIONS_LIST_SUCCESS =
  "TOKEN_DEPOSIT_TRANSACTIONS_LIST_SUCCESS";
export const TOKEN_DEPOSIT_TRANSACTIONS_LIST_FAILURE =
  "TOKEN_DEPOSIT_TRANSACTIONS_LIST_FAILURE";
export const MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START =
  "MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START";

export const TOKEN_SWAP_START = "TOKEN_SWAP_START";
export const TOKEN_SWAP_SUCCESS = "TOKEN_SWAP_SUCCESS";
export const TOKEN_SWAP_FAILURE = "TOKEN_SWAP_FAILURE";

export const TOKEN_SWAP_TRANSACTIONS_LIST_START =
  "TOKEN_SWAP_TRANSACTIONS_LIST_START";
export const TOKEN_SWAP_TRANSACTIONS_LIST_SUCCESS =
  "TOKEN_SWAP_TRANSACTIONS_LIST_SUCCESS";
export const TOKEN_SWAP_TRANSACTIONS_LIST_FAILURE =
  "TOKEN_SWAP_TRANSACTIONS_LIST_FAILURE";
export const MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START =
  "MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const GET_CURRENCIES_LIST_START = "GET_CURRENCIES_LIST_START";
export const GET_CURRENCIES_LIST_SUCCESS = "GET_CURRENCIES_LIST_SUCCESS";
export const GET_CURRENCIES_LIST_FAILURE = "GET_CURRENCIES_LIST_FAILURE";

// New Buy Flow

export const GET_ADMIN_WALLET_START = "GET_ADMIN_WALLET_START";
export const GET_ADMIN_WALLET_SUCCESS = "GET_ADMIN_WALLET_SUCCESS";
export const GET_ADMIN_WALLET_FAILURE = "GET_ADMIN_WALLET_FAILURE";

export const SELL_TOKEN_START = "SELL_TOKEN_START";
export const SELL_TOKEN_SUCCESS = "SELL_TOKEN_SUCCESS";
export const SELL_TOKEN_FAILURE = "SELL_TOKEN_FAILURE";

export const SET_TOKEN_SELL_DATA = "SET_TOKEN_SELL_DATA";
export const RESET_TOKEN_SELL_DATA = "RESET_TOKEN_SELL_DATA";

export const SELL_TOKEN_TRANSACTIONS_LIST_START =
  "SELL_TOKEN_TRANSACTIONS_LIST_START";
export const SELL_TOKEN_TRANSACTIONS_LIST_SUCCESS =
  "SELL_TOKEN_TRANSACTIONS_LIST_SUCCESS";
export const SELL_TOKEN_TRANSACTIONS_LIST_FAILURE =
  "SELL_TOKEN_TRANSACTIONS_LIST_FAILURE";
export const SELL_TOKEN_MORE_TRANSACTIONS_LIST_START =
  "SELL_TOKEN_MORE_TRANSACTIONS_LIST_START";

export const EXCHANGE_TOKEN_TRANSACTIONS_LIST_START =
  "EXCHANGE_TOKEN_TRANSACTIONS_LIST_START";
export const EXCHANGE_TOKEN_TRANSACTIONS_LIST_SUCCESS =
  "EXCHANGE_TOKEN_TRANSACTIONS_LIST_SUCCESS";
export const EXCHANGE_TOKEN_TRANSACTIONS_LIST_FAILURE =
  "EXCHANGE_TOKEN_TRANSACTIONS_LIST_FAILURE";
export const EXCHANGE_TOKEN_MORE_TRANSACTIONS_LIST_START =
  "SELL_TOKEN_MORE_TRANSACTIONS_LIST_START";

// Transfers

export const ADD_BENEFICIARIES_START = "ADD_BENEFICIARIES_START";
export const ADD_BENEFICIARIES_SUCCESS = "ADD_BENEFICIARIES_SUCCESS";
export const ADD_BENEFICIARIES_FAILURE = "ADD_BENEFICIARIES_FAILURE";

export const DELETE_BENEFICIARIES_START = "DELETE_BENEFICIARIES_START";
export const DELETE_BENEFICIARIES_SUCCESS = "DELETE_BENEFICIARIES_SUCCESS";
export const DELETE_BENEFICIARIES_FAILURE = "DELETE_BENEFICIARIES_FAILURE";

export const BENEFICIARIES_LIST_START = "BENEFICIARIES_LIST_START";
export const BENEFICIARIES_LIST_SUCCESS = "BENEFICIARIES_LIST_SUCCESS";
export const BENEFICIARIES_LIST_FAILURE = "BENEFICIARIES_LIST_FAILURE";

export const SEND_IBAN_TRANSFER_START = "SEND_IBAN_TRANSFER_START";
export const SEND_IBAN_TRANSFER_SUCCESS = "SEND_IBAN_TRANSFER_SUCCESS";
export const SEND_IBAN_TRANSFER_FAILURE = "SEND_IBAN_TRANSFER_FAILURE";

export const IBAN_TRANSFER_LIST_START = "IBAN_TRANSFER_LIST_START";
export const IBAN_TRANSFER_LIST_SUCCESS = "IBAN_TRANSFER_LIST_SUCCESS";
export const IBAN_TRANSFER_LIST_FAILURE = "IBAN_TRANSFER_LIST_FAILURE";
export const MORE_TRANSFER_IBAN_LIST_START = "MORE_TRANSFER_IBAN_LIST_START";

export const IBAN_TRANSFER_VIEW_START = "IBAN_TRANSFER_VIEW_START";
export const IBAN_TRANSFER_VIEW_SUCCESS = "IBAN_TRANSFER_VIEW_SUCCESS";
export const IBAN_TRANSFER_VIEW_FAILURE = "IBAN_TRANSFER_VIEW_FAILURE";

export const GET_IBAN_CURRENCIES_LIST_START = "GET_IBAN_CURRENCIES_LIST_START";
export const GET_IBAN_CURRENCIES_LIST_SUCCESS =
  "GET_IBAN_CURRENCIES_LIST_SUCCESS";
export const GET_IBAN_CURRENCIES_LIST_FAILURE =
  "GET_IBAN_CURRENCIES_LIST_FAILURE";

export const GET_IBAN_WALLETS_LIST_START = "GET_IBAN_WALLETS_LIST_START";
export const GET_IBAN_WALLETS_LIST_SUCCESS = "GET_IBAN_WALLETS_SUCCESS";
export const GET_IBAN_WALLETS_LIST_FAILURE = "GET_IBAN_WALLETS_LIST_FAILURE";

export const BUY_TOKENS_EXPORT_START = "BUY_TOKENS_EXPORT_START";
export const BUY_TOKENS_EXPORT_SUCCESS = "BUY_TOKENS_EXPORT_SUCCESS";
export const BUY_TOKENS_EXPORT_FAILURE = "BUY_TOKENS_EXPORT_FAILURE";

export const SELL_TOKENS_EXPORT_START = "SELL_TOKENS_EXPORT_START";
export const SELL_TOKENS_EXPORT_SUCCESS = "SELL_TOKENS_EXPORT_SUCCESS";
export const SELL_TOKENS_EXPORT_FAILURE = "SELL_TOKENS_EXPORT_FAILURE";

export const DEPOSIT_TOKENS_EXPORT_START = "DEPOSIT_TOKENS_EXPORT_START";
export const DEPOSIT_TOKENS_EXPORT_SUCCESS = "DEPOSIT_TOKENS_EXPORT_SUCCESS";
export const DEPOSIT_TOKENS_EXPORT_FAILURE = "DEPOSIT_TOKENS_EXPORT_FAILURE";

export const SWAP_TOKENS_EXPORT_START = "SWAP_TOKENS_EXPORT_START";
export const SWAP_TOKENS_EXPORT_SUCCESS = "SWAP_TOKENS_EXPORT_SUCCESS";
export const SWAP_TOKENS_EXPORT_FAILURE = "SWAP_TOKENS_EXPORT_FAILURE";

export const BUY_TOKENS_VIEW_START = "BUY_TOKENS_VIEW_START";
export const BUY_TOKENS_VIEW_SUCCESS = "BUY_TOKENS_VIEW_SUCCESS";
export const BUY_TOKENS_VIEW_FAILURE = "BUY_TOKENS_VIEW_FAILURE";

export const SELL_TOKENS_VIEW_START = "SELL_TOKENS_VIEW_START";
export const SELL_TOKENS_VIEW_SUCCESS = "SELL_TOKENS_VIEW_SUCCESS";
export const SELL_TOKENS_VIEW_FAILURE = "SELL_TOKENS_VIEW_FAILURE";

export const EXCHANGE_TOKENS_VIEW_START = "EXCHANGE_TOKENS_VIEW_START";
export const EXCHANGE_TOKENS_VIEW_SUCCESS = "EXCHANGE_TOKENS_VIEW_SUCCESS";
export const EXCHANGE_TOKENS_VIEW_FAILURE = "EXCHANGE_TOKENS_VIEW_FAILURE";

export const EXCHANGE_TOKENS_EXPORT_START = "EXCHANGE_TOKENS_EXPORT_START";
export const EXCHANGE_TOKENS_EXPORT_SUCCESS = "EXCHANGE_TOKENS_EXPORT_SUCCESS";
export const EXCHANGE_TOKENS_EXPORT_FAILURE = "EXCHANGE_TOKENS_EXPORT_FAILURE";

export const DEPOSIT_TOKENS_VIEW_START = "DEPOSIT_TOKENS_VIEW_START";
export const DEPOSIT_TOKENS_VIEW_SUCCESS = "DEPOSIT_TOKENS_VIEW_SUCCESS";
export const DEPOSIT_TOKENS_VIEW_FAILURE = "DEPOSIT_TOKENS_VIEW_FAILURE";

export const SWAP_TOKENS_VIEW_START = "SWAP_TOKENS_VIEW_START";
export const SWAP_TOKENS_VIEW_SUCCESS = "SWAP_TOKENS_VIEW_SUCCESS";
export const SWAP_TOKENS_VIEW_FAILURE = "SWAP_TOKENS_VIEW_FAILURE";

export const CARD_TRANSACTIONS_START = "CARD_TRANSACTIONS_START";
export const CARD_TRANSACTIONS_SUCCESS = "CARD_TRANSACTIONS_SUCCESS";
export const CARD_TRANSACTIONS_FAILURE = "CARD_TRANSACTIONS_FAILURE";

export const CONTACT_FORM_START = "CONTACT_FORM_START";
export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS";
export const CONTACT_FORM_FAILURE = "CONTACT_FORM_FAILURE";

export const WALLET_PAYMENTS_EXPORT_START = "WALLET_PAYMENTS_EXPORT_START";
export const WALLET_PAYMENTS_EXPORT_SUCCESS = "WALLET_PAYMENTS_EXPORT_SUCCESS";
export const WALLET_PAYMENTS_EXPORT_FAILURE = "WALLET_PAYMENTS_EXPORT_FAILURE";

export const USER_WITHDRAWALS_EXPORT_START = "USER_WITHDRAWALS_EXPORT_START";
export const USER_WITHDRAWALS_EXPORT_SUCCESS =
  "USER_WITHDRAWALS_EXPORT_SUCCESS";
export const USER_WITHDRAWALS_EXPORT_FAILURE =
  "USER_WITHDRAWALS_EXPORT_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const BANK_LIST_START = "BANK_LIST_START";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";
export const BANK_LIST_FAILURE = "BANK_LIST_FAILURE";

export const CREATE_RECIPIENT_START = "CREATE_RECIPIENT_START";
export const CREATE_RECIPIENT_SUCCESS = "CREATE_RECIPIENT_SUCCESS";
export const CREATE_RECIPIENT_FAILURE = "CREATE_RECIPIENT_FAILURE";

export const RECIPIENT_LIST_START = "RECIPIENT_LIST_START";
export const RECIPIENT_LIST_SUCCESS = "RECIPIENT_LIST_SUCCESS";
export const RECIPIENT_LIST_FAILURE = "RECIPIENT_LIST_FAILURE";

export const SINGLE_RECIPIENT_START = "SINGLE_RECIPIENT_START";
export const SINGLE_RECIPIENT_SUCCESS = "SINGLE_RECIPIENT_SUCCESS";
export const SINGLE_RECIPIENT_FAILURE = "SINGLE_RECIPIENT_FAILURE";

export const GENERATE_QUOTE_START = "GENERATE_QUOTE_START";
export const GENERATE_QUOTE_SUCCESS = "GENERATE_QUOTE_SUCCESS";
export const GENERATE_QUOTE_FAILURE = "GENERATE_QUOTE_FAILURE";

export const ONBOARDING_START = "ONBOARDING_START";
export const ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS";
export const ONBOARDING_FAILURE = "ONBOARDING_FAILURE";

export const BUSINESS_ONBOARDING_START = "BUSINESS_ONBOARDING_START";
export const BUSINESS_ONBOARDING_SUCCESS = "BUSINESS_ONBOARDING_SUCCESS";
export const BUSINESS_ONBOARDING_FAILURE = "BUSINESS_ONBOARDING_FAILURE";

export const BUSINESS_DOCUMENTS_INFO_START = "BUSINESS_DOCUMENTS_INFO_START";
export const BUSINESS_DOCUMENTS_INFO_SUCCESS = "BUSINESS_DOCUMENTS_INFO_SUCCESS";
export const BUSINESS_DOCUMENTS_INFO_FAILURE = "BUSINESS_DOCUMENTS_INFO_FAILURE";

export const UPDATE_BUSINESS_DOCUMENT_START = "UPDATE_BUSINESS_DOCUMENT_START";
export const UPDATE_BUSINESS_DOCUMENT_SUCCESS = "UPDATE_BUSINESS_DOCUMENT_SUCCESS";
export const UPDATE_BUSINESS_DOCUMENT_FAILURE = "UPDATE_BUSINESS_DOCUMENT_FAILURE";

export const BUSINESS_DOCUMENTS_STATUS_START = "BUSINESS_DOCUMENTS_STATUS_START";
export const BUSINESS_DOCUMENTS_STATUS_SUCCESS = "BUSINESS_DOCUMENTS_STATUS_SUCCESS";
export const BUSINESS_DOCUMENTS_STATUS_FAILURE = "BUSINESS_DOCUMENTS_STATUS_FAILURE";

export const WITHDRAWAL_STATUS_START = "WITHDRAWAL_STATUS_START";
export const WITHDRAWAL_STATUS_SUCCESS = "WITHDRAWAL_STATUS_SUCCESS";
export const WITHDRAWAL_STATUS_FAILURE = "WITHDRAWAL_STATUS_FAILURE";

export const EMAIL_VERIFICATION_START = "EMAIL_VERIFICATION_START";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE";

export const EMAIL_VERIFICATION_CODE_START = "EMAIL_VERIFICATION_CODE_START";
export const EMAIL_VERIFICATION_CODE_SUCCESS =
  "EMAIL_VERIFICATION_CODE_SUCCESS";
export const EMAIL_VERIFICATION_CODE_FAILURE =
  "EMAIL_VERIFICATION_CODE_FAILURE";

export const TWO_STEP_VERIFICATION_START = "TWO_STEP_VERIFICATION_START";
export const TWO_STEP_VERIFICATION_SUCCESS = "TWO_STEP_VERIFICATION_SUCCESS";
export const TWO_STEP_VERIFICATION_FAILURE = "TWO_STEP_VERIFICATION_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_FORGOT_PASSWORD_START = "VERIFY_FORGOT_PASSWORD_START";
export const VERIFY_FORGOT_PASSWORD_SUCCESS = "VERIFY_FORGOT_PASSWORD_SUCCESS";
export const VERIFY_FORGOT_PASSWORD_FAILURE = "VERIFY_FORGOT_PASSWORD_FAILURE";

export const RESEND_OTP_START = "RESEND_OTP_START";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

export const BUY_TOKENS_VIA_WALLET_START = "BUY_TOKENS_VIA_WALLET_START";
export const BUY_TOKENS_VIA_WALLET_SUCCESS = "BUY_TOKENS_VIA_WALLET_SUCCESS";
export const BUY_TOKENS_VIA_WALLET_FAILURE = "BUY_TOKENS_VIA_WALLET_FAILURE";

export const DEPOSIT_TOKENS_VIA_BANK_START = "DEPOSIT_TOKENS_VIA_BANK_START";
export const DEPOSIT_TOKENS_VIA_BANK_SUCCESS =
  "DEPOSIT_TOKENS_VIA_BANK_SUCCESS";
export const DEPOSIT_TOKENS_VIA_BANK_FAILURE =
  "DEPOSIT_TOKENS_VIA_BANK_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const CRYPTO_WITHDRAWAL_REQUEST_START =
  "CRYPTO_WITHDRAWAL_REQUEST_START";
export const CRYPTO_WITHDRAWAL_REQUEST_SUCCESS =
  "CRYPTO_WITHDRAWAL_REQUEST_SUCCESS";
export const CRYPTO_WITHDRAWAL_REQUEST_FAILURE =
  "CRYPTO_WITHDRAWAL_REQUEST_FAILURE";

export const GET_SWAP_WALLETS_LIST_START = "GET_SWAP_WALLETS_LIST_START";
export const GET_SWAP_WALLETS_LIST_SUCCESS = "GET_SWAP_WALLETS_LIST_SUCCESS";
export const GET_SWAP_WALLETS_LIST_FAILURE = "GET_SWAP_WALLETS_LIST_FAILURE";

export const CRYPTO_CURRENCY = "CRYPTO_CURRENCY";

export const GET_CURRENCY_PAIR_LIST_START = "GET_CURRENCY_PAIR_LIST_START";
export const GET_CURRENCY_PAIR_LIST_SUCCESS = "GET_CURRENCY_PAIR_LIST_SUCCESS";
export const GET_CURRENCY_PAIR_LIST_FAILURE = "GET_CURRENCY_PAIR_LIST_FAILURE";

export const TOKEN_TRADE_TRANSACTION_START = "TOKEN_TRADE_TRANSACTION_START";
export const TOKEN_TRADE_TRANSACTION_SUCCESS =
  "TOKEN_TRADE_TRANSACTION_SUCCESS";
export const TOKEN_TRADE_TRANSACTION_FAILURE =
  "TOKEN_TRADE_TRANSACTION_FAILURE";

export const TOKEN_TRADE_TRANSACTION_LIST_START =
  "TOKEN_TRADE_TRANSACTION_LIST_START";
export const TOKEN_TRADE_TRANSACTION_LIST_SUCCESS =
  "TOKEN_TRADE_TRANSACTION_LIST_SUCCESS";
export const TOKEN_TRADE_TRANSACTION_LIST_FAILURE =
  "TOKEN_TRADE_TRANSACTION_LIST_FAILURE";

export const ENABLE_DISABLE_TWOSTEP_AUTH_START =
  "ENABLE_DISABLE_TWOSTEP_AUTH_START";
export const ENABLE_DISABLE_TWOSTEP_AUTH_SUCCESS =
  "ENABLE_DISABLE_TWOSTEP_AUTH_SUCCESS";
export const ENABLE_DISABLE_TWOSTEP_AUTH_FAILURE =
  "ENABLE_DISABLE_TWOSTEP_AUTH_FAILURE";

export const CURRENCY_PAIR_START = "CURRENCY_PAIR_START";

export const SINGLE_TRADE_TRANSACTION_START = "SINGLE_TRADE_TRANSACTION_START";
export const SINGLE_TRADE_TRANSACTION_SUCCESS =
  "SINGLE_TRADE_TRANSACTION_SUCCESS";
export const SINGLE_TRADE_TRANSACTION_FAILURE =
  "SINGLE_TRADE_TRANSACTION_FAILURE";

export const EXPORT_TRADE_TRANSACTION_START = "EXPORT_TRADE_TRANSACTION_START";
export const EXPORT_TRADE_TRANSACTION_SUCCESS =
  "EXPORT_TRADE_TRANSACTION_SUCCESS";
export const EXPORT_TRADE_TRANSACTION_FAILURE =
  "EXPORT_TRADE_TRANSACTION_FAILURE";

export const EVENT_KEY_START = "EVENT_KEY_START";

export const SINGLE_WITHDRAW_TRANSACTION_START =
  "SINGLE_WITHDRAW_TRANSACTION_START";
export const SINGLE_WITHDRAW_TRANSACTION_SUCCESS =
  "SINGLE_WITHDRAW_TRANSACTION_SUCCESS";
export const SINGLE_WITHDRAW_TRANSACTION_FAILURE =
  "SINGLE_WITHDRAW_TRANSACTION_FAILURE";

export const GET_SWAP_CURRENCIES_START = "GET_SWAP_CURRENCIES_START";
export const GET_SWAP_CURRENCIES_SUCCESS = "GET_SWAP_CURRENCIES_SUCCESS";
export const GET_SWAP_CURRENCIES_FAILURE = "GET_SWAP_CURRENCIES_FAILURE";

export const SET_TOKEN_EXCHANGE_DATA = "SET_TOKEN_EXCHANGE_DATA";
export const RESET_TOKEN_EXCHANGE_DATA = "RESET_TOKEN_EXCHANGE_DATA";

export const EXCHANGE_CRYPTO_START = "EXCHANGE_CRYPTO_START";
export const EXCHANGE_CRYPTO_SUCCESS = "EXCHANGE_CRYPTO_SUCCESS";
export const EXCHANGE_CRYPTO_FAILURE = "EXCHANGE_CRYPTO_FAILURE";

export const GET_FOREX_EXCHANGE_RATE_START = "GET_FOREX_EXCHANGE_RATE_START";
export const GET_FOREX_EXCHANGE_RATE_SUCCESS = "GET_FOREX_EXCHANGE_RATE_SUCCESS";
export const GET_FOREX_EXCHANGE_RATE_FAILURE = "GET_FOREX_EXCHANGE_RATE_FAILURE";

export const GET_FOREX_SUPPORTED_CURRENCIES_START = "GET_FOREX_SUPPORTED_CURRENCIES_START";
export const GET_FOREX_SUPPORTED_CURRENCIES_SUCCESS = "GET_FOREX_SUPPORTED_CURRENCIES_SUCCESS";
export const GET_FOREX_SUPPORTED_CURRENCIES_FAILURE = "GET_FOREX_SUPPORTED_CURRENCIES_FAILURE";

export const GET_SUPPORTED_COUNTRIES_START = "GET_SUPPORTED_COUNTRIES_START";
export const GET_SUPPORTED_COUNTRIES_SUCCESS = "GET_SUPPORTED_COUNTRIES_SUCCESS";
export const GET_SUPPORTED_COUNTRIES_FAILURE = "GET_SUPPORTED_COUNTRIES_FAILURE";

export const PURPOSE_OF_PAYMENT_START = "PURPOSE_OF_PAYMENT_START";
export const PURPOSE_OF_PAYMENT_SUCCESS = "PURPOSE_OF_PAYMENT_SUCCESS";
export const PURPOSE_OF_PAYMENT_FAILURE = "PURPOSE_OF_PAYMENT_FAILURE";

export const MOBILE_CODES_LIST_START = "MOBILE_CODES_LIST_START";
export const MOBILE_CODES_LIST_SUCCESS = "MOBILE_CODES_LIST_SUCCESS";
export const MOBILE_CODES_LIST_FAILURE = "MOBILE_CODES_LIST_FAILURE";