import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  BANK_LIST_START,
  CREATE_RECIPIENT_START,
  MOBILE_CODES_LIST_START,
  RECIPIENT_LIST_START,
  SINGLE_RECIPIENT_START,
} from "../actions/ActionConstant";
import {
  bankListSuccess,
  bankListFailure,
  createRecipientSuccess,
  createRecipientFailure,
  recipientListSuccess,
  recipientListFailure,
  singleRecipientSuccess,
  singleRecipientFailure,
  mobileCodesListSuccess,
  mobileCodesListFailure,
} from "../actions/RecipientAction";

function* bankListAPI(action) {
  yield getCommonSaga({
    apiUrl: "beneficiaries/bank_search",
    payload: action.data,
    success: bankListSuccess,
    failure: bankListFailure,
  });
}

function* createRecipientAPI(action) {
  yield getCommonSaga({
    apiUrl: "beneficiary_accounts/store",
    payload: action.data,
    success: createRecipientSuccess,
    failure: createRecipientFailure,
  });
}

function* recipientListAPI(action) {
  yield getCommonSaga({
    apiUrl: "beneficiary_accounts",
    payload: action.data,
    success: recipientListSuccess,
    failure: recipientListFailure,
  });
}

function* singleRecipientAPI(action) {
  yield getCommonSaga({
    apiUrl: "beneficiary_accounts/show",
    payload: action.data,
    success: singleRecipientSuccess,
    failure: singleRecipientFailure,
  });
}

function* mobileCodesListAPI(action) {
  yield getCommonSaga({
    apiUrl: "mobile_country_codes",
    payload: action.data,
    success: mobileCodesListSuccess,
    failure: mobileCodesListFailure,
  });
}

export default function* RecipientSaga() {
  yield all([yield takeLatest(BANK_LIST_START, bankListAPI)]);
  yield all([yield takeLatest(CREATE_RECIPIENT_START, createRecipientAPI)]);
  yield all([yield takeLatest(RECIPIENT_LIST_START, recipientListAPI)]);
  yield all([yield takeLatest(SINGLE_RECIPIENT_START, singleRecipientAPI)]);
  yield all([yield takeLatest(MOBILE_CODES_LIST_START, mobileCodesListAPI)]);
}
