import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const FutureOfFinance = () => {
  const t = useTranslation("future_finance");

  return (
    <>
      <div className="growth-of-exchange-sec sm-padding bit-coin-frame">
        <Container fluid>
          <Row className="align-items-center">
            <Col xl={6}>
              <div className="growth-of-exchange-info">
                <h2
                  data-aos="fade-up"
                >
                  {t("navigate")}{" "}
                  <span>{t("the_future_of_finance_with_confidence")}</span>
                </h2>
                <p
                  data-aos="fade-up"
                >
                  {configuration.get("configData.site_name")}{" "}
                  {t("is_more_than_a_platform_para")}
                </p>
              </div>
              <div className="growth-of-exchange-list-box">
                <div
                  className="growth-of-exchange-list-card"
                  data-aos="fade-up"
                >
                  <div className="growth-of-exchange-list-icon-sec">
                  <CustomLazyLoad
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin +
                        "/img/landing/experience-catalyzes-growth/experienc-catalyzes-growth-icon-1.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("mobile_accessibility")}</h4>
                    <p>{t("trade_on_the_move_with_our_user_centric_para")}</p>
                  </div>
                </div>
                <div
                  className="growth-of-exchange-list-card"
                  data-aos="fade-up"
                >
                  <div className="growth-of-exchange-list-icon-sec">
                  <CustomLazyLoad
                      className="growth-of-exchange-list-icon"
                      src={
                        window.location.origin +
                        "/img/landing/experience-catalyzes-growth/experienc-catalyzes-growth-icon-2.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="growth-of-exchange-list-info">
                    <h4>{t("innovative_features")}</h4>
                    <p>{t("explore_the_edge_of_crypto_para")}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="future-of-finance-img-sec">
                <Image
                  className="future-of-finance-img"
                  data-aos="fade-up"
                  src={
                    window.location.origin + "/img/gif/future-of-finance.gif"
                  }
                  type="image/png"
                />
              </div>

              {/* <div className="why-choose-fram-avater">
                <div className="currency-coin-wrap" data-aos="zoom-out-right">
                  <Image
                    className="currency-coins"
                    src={window.location.origin + "/img/gif/coins/bitcoin.png"}
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={window.location.origin + "/img/gif/coins/t-logo.png"}
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={
                      window.location.origin + "/img/gif/coins/shape-coin.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={
                      window.location.origin + "/img/gif/coins/star-coin.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={window.location.origin + "/img/gif/coins/pi-coin.png"}
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={window.location.origin + "/img/gif/coins/l-logo.png"}
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={window.location.origin + "/img/gif/coins/a-logo.png"}
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={window.location.origin + "/img/gif/coins/d-logo.png"}
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={
                      window.location.origin + "/img/gif/coins/8-shape-logo.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={
                      window.location.origin + "/img/gif/coins/page-coin.png"
                    }
                    type="image/png"
                  />
                </div>
                <div className="currency-coin-wrap">
                  <Image
                    className="currency-coins"
                    src={
                      window.location.origin +
                      "/img/gif/coins/six-shape-logo.png"
                    }
                    type="image/png"
                  />
                </div>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FutureOfFinance;
