import React, { useState } from "react";
import { Container, Button, Row, Form, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { useTranslation, setLanguage } from "react-multi-lang";
import Select from "react-select";

const NewFooterIndex = (props) => {
  const t = useTranslation("footer");

  // const handleChange = (selectedOption) => {
  //   setLanguage(selectedOption.value)
  //   localStorage.setItem('lang', selectedOption.value);
  // };

  const options = [{ value: "en", label: t("english") }];

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #e47c3f!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "35px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#e47c3f",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#e47c3f",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "800",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#e47c3f!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#e47c3f",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  return (
    <>
      <div className="new-footer-sec sm-padding">
        <Container fluid>
          <div className="call-to-action-box">
            <div className="call-to-action-left-sec">
              <h3>Join Us and Step Into the Future of Crypto Trading!</h3>
              <p>HERALDEX is more than just an exchange—it's your trusted partner in the crypto world. From secure transactions to a user-friendly experience, we aim to empower every trader, whether you're buying your first cryptocurrency or managing a diversified portfolio.</p>
              <div className="call-to-action-btn-sec">
                <a className="new-default-primary-btn" href="#contact">
                  {t("heading")}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="#171717" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"></path></svg>
                </a>
              </div>
            </div>
            <div className="call-to-action-right-img-sec">
              <Image
                className="call-to-action-right-img"
                src={
                  window.location.origin + "/img/landing/cta/cta-right-img.png"
                }
                type="image/png"
              />
            </div>
          </div>
          <div className="footer-link-sec">
            <ul className="list-unstyled footer-link-list">
              <li>
              <Link
                to="/user-agreement"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                User Agreement
              </Link>
              </li>
              <li>
              <Link
                to="/privacy-agreement"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                Privacy Agreement
              </Link>
              </li>
              <li>
              <Link
                to="/risk-disclosure-policy"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                Risk Disclosure Policy
              </Link>
              </li>
              <li>
              <Link
                to="/anti-money-laundering"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                Anti-Money Laundering
              </Link>
              </li>
              <li>
              <Link
                to="/terms-conditions"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                Terms Conditions
              </Link>
              </li>
              <li>
              <a
                href="https://heraldexchange.freshdesk.com/support/solutions"
                target="_blank"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                Knowledge Base
              </a>
              </li>
              <li>
              <a
                href="https://heraldexchange.freshdesk.com/support/tickets/new"
                target="_blank"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 256 256"
                    fill="#fff"
                  >
                    <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                  </svg>
                </span>
                Support Ticket
              </a>
              </li>
            </ul>
          </div>
          {/* <div className="footer-quick-link">
         <Row>
          <Col lg={2}>
                <div className="footer-quick-link-card">
                  <div className="footer-quick-link-card-titles">
                    <h4>Buy Crypto</h4>
                  </div>
                  <div className="footer-quick-link-card-link">
                    <ul className="list-unstyled">
                      <li>
                         <Link>Buy Bitcoin (BTC) </Link>
                      </li>
                      <li>
                         <Link>Buy Ethereum (ETH) </Link>
                      </li>
                      <li>
                         <Link>Buy Tether (USDT)</Link>
                      </li>
                      <li>
                         <Link>Buy Binance Coin (BNB)</Link>
                      </li>
                      <li>
                         <Link>Buy USD Coin (USDC)</Link>
                      </li>
                      <li>
                         <Link>Buy Ripple (XRP)</Link>
                      </li>
                
                    </ul>
                  </div>
                </div>
          </Col>
          <Col lg={2}>
          <div className="footer-quick-link-card">
                  <div className="footer-quick-link-card-titles">
                    <h4>Sell Crypto</h4>
                  </div>
                  <div className="footer-quick-link-card-link">
                    <ul className="list-unstyled">
                      <li>
                         <Link>Sell Bitcoin (BTC) </Link>
                      </li>
                      <li>
                         <Link>Sell Ethereum (ETH) </Link>
                      </li>
                      <li>
                         <Link>Sell Tether (USDT)</Link>
                      </li>
                      <li>
                         <Link>Sell Binance Coin (BNB)</Link>
                      </li>
                      <li>
                         <Link>Sell USD Coin (USDC)</Link>
                      </li>
                      <li>
                         <Link>Sell Ripple (XRP)</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
          </Col>
          <Col lg={3}>
          <div className="footer-quick-link-card">
                  <div className="footer-quick-link-card-titles">
                    <h4>Exchange</h4>
                  </div>
                  <div className="footer-quick-link-card-link">
                    <ul className="list-unstyled">
                      <li>
                         <Link>Exchange Bitcoin (BTC)</Link>
                      </li>
                      <li>
                         <Link>Exchange Ethereum (ETH)</Link>
                      </li>
                      <li>
                         <Link>Exchange Tether (USDT ERC20)</Link>
                      </li>
                      <li>
                         <Link>Exchange Tether (USDT TRC20)</Link>
                      </li>
                      <li>
                         <Link>Exchange Circle USD (USDC)</Link>
                      </li>
                      <li>
                         <Link>Exchange Monero (XMR)</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
          </Col>
          <Col lg={5}>
    
          <div className="footer-quick-link-card">
                  <div className="footer-quick-link-card-titles">
                    <h4>Sell Crypto</h4>
                  </div>
                  <div className="footer-quick-layout_3">
                  <div className="footer-quick-link-card-link">
                    <ul className="list-unstyled">
                      <li>
                         <Link>BTC to XMR </Link>
                      </li>
                      <li>
                         <Link>BTC to USDT </Link>
                      </li>
                      <li>
                         <Link>USDT to XMR</Link>
                      </li>
                      <li>
                         <Link>ETH to XMR</Link>
                      </li>
                      <li>
                         <Link>ETH to BTC</Link>
                      </li>
                      <li>
                         <Link>XMR to BTC</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-quick-link-card-link">
                    <ul className="list-unstyled">
                      <li>
                         <Link>USDT to TRX</Link>
                      </li>
                      <li>
                         <Link>LTC to XMR</Link>
                      </li>
                      <li>
                         <Link>ETH to BNB</Link>
                      </li>
                      <li>
                         <Link>BNB to BTC</Link>
                      </li>
                      <li>
                         <Link>XMR to LTC</Link>
                      </li>
                      <li>
                         <Link>XMR to ETH</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-quick-link-card-link">
                    <ul className="list-unstyled">
                      <li>
                         <Link>SOL to ETH </Link>
                      </li>
                      <li>
                         <Link>USDT to ETH </Link>
                      </li>
                      <li>
                         <Link>USDT to BNB</Link>
                      </li>
                      <li>
                         <Link>BTC to LTC</Link>
                      </li>
                      <li>
                         <Link>ETH to USDT</Link>
                      </li>
                      <li>
                         <Link>BTC to TRX</Link>
                      </li>
                    </ul>
                  </div>
                </div>
          </div>
          </Col>
         </Row>
         </div> */}
          {/* {configuration.get("configData") &&
            configuration.get("configData.footer_pages3").length > 0 && (
              <div className="footer-link-sec">
                <ul className="list-unstyled footer-link-list">
                  {configuration
                    .get("configData.footer_pages3")
                    .map((footer_page) => (
                      <li>
                        <Link
                          to={`/page/${footer_page.static_page_unique_id}`}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              data-name="Layer 1"
                              viewBox="0 0 256 256"
                              fill="#fff"
                            >
                              <path d="M81.535 172.819a10 10 0 01-7.091-17.051L102.053 128l-27.609-27.768a10 10 0 1114.182-14.1l34.62 34.818a10 10 0 010 14.1l-34.62 34.818a9.968 9.968 0 01-7.091 2.951zm25.926 36.752l74.1-74.52a10 10 0 000-14.1l-74.1-74.52a10 10 0 10-14.182 14.1L160.363 128l-67.084 67.47a10 10 0 0014.182 14.1z"></path>
                            </svg>
                          </span>
                          {footer_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )} */}
          <div className="footer-item-box">
            <div className="footer-about-card">
              <div className="footer-about-logo-img-sec">
                {/* <Image
                  className="footer-about-logo-img"
                  src={configuration.get("configData.site_logo")}
                  type="image/png"
                /> */}
                <Image
                  className="footer-about-logo-img"
                  src={window.location.origin + "/img/logo-exchange-white.png"}
                  type="image/png"
                />
              </div>
              <div className="footer-copyright-sec">
                <p>{configuration.get("configData.copyright_content")}</p>
              </div>
              {/* <div className="-mr-1.5 flex items-center space-x-2">
                <Select
                  options={options}
                  styles={customStyles}
                  isSearchable={false}
                  value={options.find(opt => opt.value === localStorage.getItem("lang"))}
                  onChange={handleChange}
                />
              </div> */}
            </div>
            <div className="footer-social-link-sec">
              {configuration.get("configData.facebook_link") ||
              configuration.get("configData.instagram_link") ||
              configuration.get("configData.twitter_link") ||
              configuration.get("configData.linkedin_link") ? (
                <h4>{t("connect_us")}</h4>
              ) : null}
              <ul className="list-unstyled">
                {configuration.get("configData.facebook_link") && (
                  <li>
                    <a
                      href={configuration.get("configData.facebook_link")}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 155.139 155.139"
                        version="1.1"
                        viewBox="0 0 155.139 155.139"
                        xmlSpace="preserve"
                        width="24"
                        height="24"
                        fill="#fff"
                      >
                        <path d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"></path>
                      </svg>
                    </a>
                  </li>
                )}
                {configuration.get("configData.instagram_link") && (
                  <li>
                    <a
                      href={configuration.get("configData.instagram_link")}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#fff"
                        viewBox="0 0 511 511.9"
                      >
                        <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0074.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0"></path>
                        <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zm0 0M423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0"></path>
                      </svg>
                    </a>
                  </li>
                )}
                {configuration.get("configData.twitter_link") && (
                  <li>
                    <a
                      href={configuration.get("configData.twitter_link")}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#fff"
                        viewBox="0 0 1226.37 1226.37"
                      >
                        <path d="M727.348 519.284L1174.075 0h-105.86L680.322 450.887 370.513 0H13.185l468.492 681.821L13.185 1226.37h105.866l409.625-476.152 327.181 476.152h357.328L727.322 519.284zM582.35 687.828l-47.468-67.894-377.686-540.24H319.8l304.797 435.991 47.468 67.894 396.2 566.721H905.661L582.35 687.854z"></path>
                      </svg>
                    </a>
                  </li>
                )}
                {configuration.get("configData.linkedin_link") && (
                  <li>
                    <a
                      href={configuration.get("configData.linkedin_link")}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#fff"
                        viewBox="0 0 512 512"
                      >
                        <path d="M7.813 509.935h132.571V161.322H7.813zm30-318.613h72.571v288.612H37.813zM74.099 2.065C33.24 2.065 0 35.306 0 76.164s33.24 74.1 74.099 74.1 74.1-33.241 74.1-74.1-33.242-74.099-74.1-74.099zM30 76.164c0-24.316 19.782-44.099 44.099-44.099s44.1 19.782 44.1 44.099-19.783 44.1-44.1 44.1S30 100.48 30 76.164zM511.679 270.79c-4.718-67.855-61.318-120.144-131.489-120.144-31.387 0-61.016 10.912-84.361 30.274v-19.6h-127.03v348.613h132.572V319.269c0-21.488 17.481-38.97 38.97-38.97 21.487 0 38.969 17.482 38.969 38.979l.128 190.656H512V271.112zM271.371 479.935h-72.572V191.321h67.029v42.847l24.005.138 4.46-6.924c18.85-29.265 50.961-46.735 85.897-46.735 55.836 0 100.543 42.602 101.78 96.985l.03 202.303h-72.582l-.108-160.666c0-38.03-30.94-68.97-68.969-68.97-38.03 0-68.97 30.94-68.97 68.97z"></path>
                      </svg>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-company-info-sec">
            <h4>Contact Us</h4>
              <ul className="list-unstyled">
                {configuration.get("configData.contact_mobile") ? (
                  <li>
                    <a href={`tel:${configuration.get("configData.contact_mobile")}`}>
                    <div class="footer-quick-card">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          enableBackground="new 0 0 25.625 25.625"
                          version="1.1"
                          viewBox="0 0 25.625 25.625"
                          xmlSpace="preserve"
                          width="24"
                          height="24"
                          fill="#fff"
                        >
                          <path d="M22.079 17.835c-1.548-1.324-3.119-2.126-4.648-.804l-.913.799c-.668.58-1.91 3.29-6.712-2.234-4.801-5.517-1.944-6.376-1.275-6.951l.918-.8c1.521-1.325.947-2.993-.15-4.71l-.662-1.04C7.535.382 6.335-.743 4.81.58l-.824.72c-.674.491-2.558 2.087-3.015 5.119-.55 3.638 1.185 7.804 5.16 12.375 3.97 4.573 7.857 6.87 11.539 6.83 3.06-.033 4.908-1.675 5.486-2.272l.827-.721c1.521-1.322.576-2.668-.973-3.995l-.931-.801z"></path>
                        </svg>
                      </span>
                      <p></p>
                      <p>{configuration.get("configData.contact_mobile")}</p>
                    </div>
                    </a>
                  </li>
                ) : null}
                {/* Commented for Tempr  */}
                {/* {configuration.get("configData.contact_email") ? ( */}
                  <li>
                    <a href="mailto:support@herald.exchange">
                      <div class="footer-quick-card">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#fff"
                          >
                            <path d="M23.954 5.542l-8.418 8.418a5.007 5.007 0 01-7.072 0L.046 5.542C.032 5.7 0 5.843 0 6v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6c0-.157-.032-.3-.046-.458z"></path>
                            <path d="M14.122 12.546l9.134-9.135A4.986 4.986 0 0019 1H5A4.986 4.986 0 00.744 3.411l9.134 9.135a3.007 3.007 0 004.244 0z"></path>
                          </svg>
                        </span>
                        <Link to="#">
                          {/* {configuration.get("configData.contact_email")} */}
                          support@herald.exchange
                        </Link>
                      </div>
                    </a>
                  </li>
                {/* ) : null} */}
                {configuration.get("configData.contact_address") ? (
                  <li>
                    <div class="footer-quick-card">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#fff"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 0A10.011 10.011 0 002 10c0 5.282 8.4 12.533 9.354 13.343l.646.546.646-.546C13.6 22.533 22 15.282 22 10A10.011 10.011 0 0012 0zm0 15a5 5 0 115-5 5.006 5.006 0 01-5 5z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                      </span>
                      <p>{configuration.get("configData.contact_address")}</p>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="footer-about-info footer-liceansed-wrap">
            <p>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="30"
                fill="none"
                viewBox="0 0 24 34"
              >
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="0.54"
                  strokeWidth="2"
                  d="M12 25c5.523 0 10-4.477 10-10S17.523 5 12 5 2 9.477 2 15s4.477 10 10 10zM12 20v-4m0-4h.01"
                ></path>
              </svg> */}
              {/* <span>{configuration.get("configData.footer_description")}</span> */}
              <span>
                SEPFOR SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ Company is a
                licensed SPI institution in Poland Liceance Number Numer KRS:
                0001017787 which has the right to execute activities related to
                issuance of e-money and provision of payment services around the
                European Union.
              </span>
            </p>
            <p>
              <span>
                Inovatasonata" UAB Company is a registered virtual currency
                exchange and virtual currency wallet operator permitted by
                Lithuanian authorities under <br></br> registration number
                :306110296
              </span>
            </p>
            <p>
              <span>
                EFI ECOM MARKETS SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ is a
                registered virtual currency exchange and virtual currency wallet
                operator permitted by Poland authorities under registration
                number :NIP: 7252324409 KRS: 0000997664
              </span>
            </p>
            {/* <p>
              {configuration.get("configData.footer_description")}
            </p> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewFooterIndex;
