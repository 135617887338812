import React, { useEffect, useState } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateSwapData } from "../../store/actions/UserAction";
import ExchangeRate from "./ExchangeRate";
import "./Swap.css";
import SwapWalletAddress from "./SwapWalletAddressSec";
import SwapCryptoPayment from "./SwapCryptoPayment";
import { useTranslation } from "react-multi-lang";
import SellSuccess from "./SellSuccess";
import SellCryptoForm from "../NewLanding/SellCryptoForm";
import { resetTokenSellData } from "../../store/actions/BuyAction";

const SwapIndex = () => {
  const dispatch = useDispatch();
  const t = useTranslation("exchange_index");
  const swapData = useSelector((state) => state.user.swapData);
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const setSwapData = (data) => dispatch(updateSwapData(data));
  const login = useSelector((state) => state.user.login);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (swapData.step == 6) {
      setSwapData({
        ...swapData,
        step: 1,
      });
    }
  }, []);

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      setIsLoggedIn(localStorage.getItem("token"));
    }
    setSkipRender(false);
  }, [login]);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);

  return (
    <>
      <div className="exchange-sec">
        <Container fluid>
          <div className="buy-crypto-sec">
            <Row className="justify-content-md-center">
              <Col md={10} xl={6} lg={6}>
                <div className="buy-flow-product-sec">
                  {tokenSellData.step == 1 ? (
                    <div className="step-1">
                      <SellCryptoForm />
                    </div>
                  ) : tokenSellData.step == 2 ? (
                    <div className="step-2">
                      <SwapWalletAddress />
                    </div>
                  ) : tokenSellData.step == 3 ? (
                    <div className="step-3">
                      <SellSuccess />
                    </div>
                  ) : tokenSellData.step >= 4 ? (
                    <div className="step-4">
                      <SwapCryptoPayment />
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SwapIndex;
