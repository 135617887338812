import {
  BANK_LIST_START,
  BANK_LIST_SUCCESS,
  BANK_LIST_FAILURE,
  CREATE_RECIPIENT_START,
  CREATE_RECIPIENT_SUCCESS,
  CREATE_RECIPIENT_FAILURE,
  RECIPIENT_LIST_START,
  RECIPIENT_LIST_SUCCESS,
  RECIPIENT_LIST_FAILURE,
  SINGLE_RECIPIENT_START,
  SINGLE_RECIPIENT_SUCCESS,
  SINGLE_RECIPIENT_FAILURE,
  MOBILE_CODES_LIST_START,
  MOBILE_CODES_LIST_SUCCESS,
  MOBILE_CODES_LIST_FAILURE,
} from "../actions/ActionConstant";
import { createRecipientFailure } from "../actions/RecipientAction";

const initialState = {
  bankList: {
    data: {},
    loading: true,
    buttonDisable: false,
    error: false,
  },
  createRecipient: {
    data: {},
    loading: false,
    buttonDisable: false,
    error: false,
  },
  recipientList: {
    data: {},
    loading: false,
    buttonDisable: false,
    error: false,
  },
  singleRecipient: {
    data: {},
    loading: false,
    buttonDisable: false,
    error: false,
  },
  mobileCodesList: {
    data: {},
    loading: false,
    buttonDisable: false,
    error: false,
  },
};

const RecipientReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANK_LIST_START:
      return {
        ...state,
        bankList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case BANK_LIST_SUCCESS:
      return {
        ...state,
        bankList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case BANK_LIST_FAILURE:
      return {
        ...state,
        bankList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case CREATE_RECIPIENT_START:
      return {
        ...state,
        createRecipient: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case CREATE_RECIPIENT_SUCCESS:
      return {
        ...state,
        createRecipient: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case CREATE_RECIPIENT_FAILURE:
      return {
        ...state,
        createRecipient: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case RECIPIENT_LIST_START:
      return {
        ...state,
        recipientList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case RECIPIENT_LIST_SUCCESS:
      return {
        ...state,
        recipientList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case RECIPIENT_LIST_FAILURE:
      return {
        ...state,
        recipientList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case SINGLE_RECIPIENT_START:
      return {
        ...state,
        singleRecipient: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case SINGLE_RECIPIENT_SUCCESS:
      return {
        ...state,
        singleRecipient: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case SINGLE_RECIPIENT_FAILURE:
      return {
        ...state,
        singleRecipient: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case MOBILE_CODES_LIST_START:
      return {
        ...state,
        mobileCodesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case MOBILE_CODES_LIST_SUCCESS:
      return {
        ...state,
        mobileCodesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case MOBILE_CODES_LIST_FAILURE:
      return {
        ...state,
        mobileCodesList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default RecipientReducer;
