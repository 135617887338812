import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const WhyChooseUs = (props) => {

  const t = useTranslation("why_chooseus");

  return (
    <>
      <div className="why-choose-us-sec sm-padding" data-aos="fade-up"
        data-aos-duration="2000" data-aos-delay="200">
        <Container fluid>
          <div className="why-choose-us-box">
            <Row className="align-items-center justify-content-center">
              <Col xl={8} lg={8} md={7}>
              <div className="why-choose-us-header-sec">
                  <h2 data-aos="fade-up" className="text-center">Why Choose HERALDEX?</h2>
                </div>
                </Col>               
            </Row>
            <Row className="mt-5">
            <Col lg={4} xl={4} className="resp-marg-btm-xs">
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/why-choose-us/advance-security-protocol.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>Advanced Security Protocols</h4>
                      <p>Protect your assets with our Triple-Layer Security, including offline cold storage, advanced encryption, and continuous monitoring to ensure your funds remain secure.</p>
                    </div>
                  </div>
                  </Col>
                  <Col lg={4} xl={4} className="resp-marg-btm-xs">
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/why-choose-us/transprent-pricing.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>Transparent Pricing</h4>
                      <p>Enjoy competitive rates and zero hidden fees, giving you full control over your investments.</p>
                    </div>
                  </div>
                  </Col>
                  <Col lg={4} xl={4} className="resp-marg-btm-xs">
                  <div className="why-choose-us-card" data-aos="fade-up">
                    <div className="why-choose-us-icon-sec">
                      <CustomLazyLoad
                        className="why-choose-us-icon"
                        src={
                          window.location.origin + "/img/why-choose-us/comprehensive-support.svg"
                        }
                        type="image/png"
                      />
                    </div>
                    <div className="why-choose-us-info">
                      <h4>Comprehensive Support</h4>
                      <p>Our dedicated support team is available 24/7, providing personalized assistance whenever you need it.</p>
                    </div>
                  </div>
                  </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default WhyChooseUs;
