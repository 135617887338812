import React from 'react';
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ExchangePlatform = () => {
    return (
        <div className="exchange-platform-frame sm-padding">
            <Container fluid>
            <Row>
          <Col md={6} lg={6} xl={5}>
              <div className="best-crypto-step-title ">
              <h2 data-aos="fade-zoom-in">Elevate Your <span>Crypto Trading Experience</span></h2>
              </div>
            </Col>
          </Row>
                <Row>
                    <Col lg={5}>
                        <div className="exchange-platfrom-info-card grdaient-blur-one h-100  aos-init aos-animate" data-aos="fade-up">
                            <div className="exchange-platfrom-primary-card">
                            <div className="exchange-platfrom-info">
                                <h3>Currencies Supported</h3>
                                <p>Pioneering seamless crypto transactions with state-of-the-art technology.</p>
                            </div>
                            <div className="exchange-platfrom-info-batch-frame">
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/usd.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        USD
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/jpy.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        JPY
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/hkd.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        HKD
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/myr.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        MYR
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/inr.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        INR
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/idr.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        IDR
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/gbp.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        GBP
                                    </div>
                                </div>
                                <div className="info-batch-card">
                                    <div className="info-batch-card-icons">
                                        <Image
                                            className="batch-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/aud.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="info-batch-card-info">
                                        AUD
                                    </div>
                                </div>
                            </div>
                         
                            </div>
                        </div>
                    </Col>
                    <Col lg={7}>
                        <div className="exchange-platfrom-info-card grdaient-blur-two aos-init aos-animate" data-aos="fade-up">
                            <div className="exchange-platfrom-info-frame">
                                <div className="exchange-platfrom-info-frame-info">
                                    <h3>Transparent Pricing</h3>
                                    <p>With HERALDEX, you’ll benefit from our clear, competitive pricing with no hidden fees, maximizing your potential returns</p>
                                </div>
                                <div className="exchange-platfrom-info-frame-avater">
                                    <Image
                                        className="platfrom-card-icons"
                                        src={window.location.origin + "/img/phase_1/icons/platfrom/avater/platfrom-avater.svg"}
                                        alt="usd"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="item-grid-layout_2 aos-init aos-animate" data-aos="fade-up">
                            <div className="exchange-platfrom-info-card grdaient-blur-color">
                                <div className="echange-platfrom-vertical-frame">
                                    <div className="echange-platfrom-vertical-avater">
                                        <Image
                                            className="vertical-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/avater/back-development.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="echange-platfrom-vertical-info">
                                        <h3>Cutting-Edge Technology</h3>
                                        <p>Pioneering seamless crypto transactions with state-of-the-art technology.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="exchange-platfrom-info-card grdaient-blur-color">
                                <div className="echange-platfrom-vertical-frame">
                                    <div className="echange-platfrom-vertical-avater">
                                        <Image
                                            className="vertical-card-icons-two"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/avater/bit-coins-book.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="echange-platfrom-vertical-info">
                                        <h3>Crypto Learning Hub</h3>
                                        <p>Whether you're a beginner or Experienced trader, our learning hub offers resources to help you master crypto trading.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ExchangePlatform;
