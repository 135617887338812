import React, { useEffect, useRef, useState } from "react";
import { Container, Button, Row, Form, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { contactFormStart } from "../../store/actions/PageAction";
import { countryData } from "../helper/CountryList";

const ContactUs = (props) => {
  const t = useTranslation("contact_us");
  const dispatch = useDispatch();
  const formRef = useRef();
  const contactForm = useSelector((state) => state.page.contactForm);
  const [formSubmit, setFormSubmit] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).trim().notOneOf([""]),
    email: Yup.string()
      .email(t("email.invalid_email"))
      .required(t("email.required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .matches(/^(?=.*[0-9])/, t("phone.space_values_not_valid")),
    message: Yup.string().required(t("message.required")).trim().notOneOf([""]),
  });

  const handleSubmit = (values) => {
    dispatch(contactFormStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !contactForm?.loading &&
      Object.keys(contactForm?.data).length > 0
    ) {
      setFormSubmit(true);
    }
    setSkipRender(false);
  }, [contactForm]);

  return (
    <>
      <div
        className="contact-us-sec sm-padding"
        // data-aos="fade-up"
        id="contact"
      >
        <Container>
          <Row className="align-items-center">
            <Col md={12} xl={6}>
              <div className="contact-us-form-box">
                <h3>{t("heading")}</h3>
                <p>We’re here to help! Whether you have questions about exchanging currencies, buying or selling crypto, or anything else related to our platform, our team is ready to assist you.</p>
                {!formSubmit ? (
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      message: "",
                      mobile: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                  >
                    {({
                      values,
                      setFieldValue,
                      setFieldTouched,
                      errors,
                      touched,
                      isValid,
                    }) => (
                      <FORM className="exchange-form">
                        <Form.Group controlId="formBasicName" className="mb-3">
                          <Form.Label>
                            {t("name.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder={t("name.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            {t("email.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder={t("email.placeholder")}
                            maxLength={128}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 register-phone-input">
                          <Form.Label htmlFor="mobile" className="form-label">
                            {t("mobile.label")} <span> *</span>
                          </Form.Label>
                          <PhoneInput
                            defaultCountry="AE"
                            countries={Object.keys(countryData)}
                            placeholder={t("mobile_number.placeholder")}
                            value={values.mobile}
                            onChange={(value) => setFieldValue("mobile", value)}
                            onBlur={() => formRef.current.submitForm()}
                            international
                            countryCallingCodeEditable={false}
                            className={`${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="formBasicSecondaryAddress"
                          className="mb-3"
                        >
                          <Form.Label>{t("message.label")}<span> *</span></Form.Label>
                          <Field
                            as="textarea"
                            rows="3"
                            type="text"
                            className="form-control"
                            name="message"
                            placeholder={t("message.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="message"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <div className="contact-us-submit-btn-sec">
                          <Button
                            className="new-default-primary-btn w-100"
                            type="submit"
                            disabled={contactForm?.buttonDisable}
                          >
                            {t("submit")}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="#171717"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                                d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                              ></path>
                            </svg>
                          </Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                ) : (
                  <div className="contact-form-info">
                    <h4>{t("thanks_for_your_message_para")}</h4>
                  </div>
                )}
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="contact-us-img-sec">
              <Image
                  className="contact-us-img"
                  // data-aos="fade-up"
                  src={window.location.origin + "/img/gif/contact.gif"}
                  type="image/png"
                /> 
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
