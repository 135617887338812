import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { singleTradeTransactionStart } from "../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import "./transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import NoDataFound from "../helper/NoDataFound";
import { useLocation } from "react-router-dom";

const TradingTransactionModal = (props) => {
  const location= useLocation()
  const { transactionId } = props;
  const dispatch = useDispatch();
  const t = useTranslation("transactions");
  const singleTradeTransaction = useSelector(
    (state) => state.buy.singleTradeTransaction
  );
  const [view, setView] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [fineryResponse, setFineryResponse] = useState("");

  useEffect(() => {
    dispatch(
      singleTradeTransactionStart({
        trade_transaction_id: transactionId,
      })
    );
    setSkipRender(false);
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !singleTradeTransaction.loading &&
      Object.keys(singleTradeTransaction.data).length > 0
    ) {
      singleTradeTransaction.data.trade_transaction.finery_response !== "" &&
        setFineryResponse(
          JSON.parse(
            singleTradeTransaction.data.trade_transaction.finery_response
          )
        );
    }
  }, [singleTradeTransaction]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">   Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {singleTradeTransaction.loading ? (
            <>
              <div className="trans-view-grid-layout">
                <Skeleton height={270} />

                <Skeleton height={270} />
              </div>

              <Skeleton height={340} />
            </>
          ) : Object.keys(singleTradeTransaction.data).length > 0 ? (
            <div className="transaction-view-card">
              <div className="trans-view-grid-layout">
                <div className="transaction-view-box">
                  <div className="view-box-card">
                    <h5 className="view-label">Tokens Transferred</h5>
                    <p className="view-amount">
                      {
                        singleTradeTransaction.data.trade_transaction
                          .to_amount_formatted
                      }
                    </p>
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">Amount Paid</h5>
                    <p className="view-value">
                      {
                        singleTradeTransaction.data.trade_transaction
                          .from_amount_formatted
                      }
                    </p>
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">Status</h5>
                    <p
                      className={`status ${
                        singleTradeTransaction.data.trade_transaction.status ==
                        1
                          ? "succes"
                          : "denied"
                      } `}
                    >
                      {
                        singleTradeTransaction.data.trade_transaction
                          .status_formatted
                      }
                    </p>
                  </div>
                </div>
                <div className="transaction-view-box">
                  <div className="view-box-card">
                    <h5 className="view-label">Exchange Value</h5>
                    <p className="view-value">
                      {
                        singleTradeTransaction.data.trade_transaction
                          .adjusted_exchange_rate_formatted
                      }
                    </p>
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">Transaction Date</h5>
                    <p className="view-value">
                      {
                        singleTradeTransaction.data.trade_transaction
                          .created_at_formatted
                      }
                    </p>
                  </div>
                  {location.pathname !="/wallet-currency-list"&&
                  <div className="view-box-card">
                    <h5 className="view-label">Transaction reference id</h5>
                    {singleTradeTransaction.data.trade_transaction.unique_id ? (
                      <>
                        <p className="view-value transaction-value">
                          {
                            singleTradeTransaction.data.trade_transaction
                              .unique_id
                          }

                          <CopyToClipboard
                            text={
                              singleTradeTransaction.data.trade_transaction
                                .unique_id
                            }
                            onCopy={() =>
                              getSuccessNotificationMessage(
                                t("payment_id_was_copied_to_clipboard")
                              )
                            }
                          >
                            <Button className="action-btn copy-btn item_center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#298BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </p>
                      </>
                    ) : (
                      "Transaction ref id not available"
                    )}
                  </div>}
                  {fineryResponse && !view && (
                    <div className="view-box-card">
                      <Button
                        className=" action-btn link-btn"
                        onClick={() => {
                          setView(true);
                        }}
                      >
                        View Addtional Details
                      </Button>
                    </div>
                    // <>
                    //   <div className="view-box-card">
                    //     <h5 className="view-label">Size</h5>
                    //     <p className="view-value">
                    //       {fineryResponse.data.deals[0].size}
                    //     </p>
                    //   </div>
                    //   <div className="view-box-card">
                    //     <h5 className="view-label">Price</h5>
                    //     <p className="view-value">
                    //       {fineryResponse.data.deals[0].price}
                    //     </p>
                    //   </div>
                    //   <div className="view-box-card">
                    //     <h5 className="view-label">Volume</h5>
                    //     <p className="view-value">
                    //       {fineryResponse.data.deals[0].volume}
                    //     </p>
                    //   </div>
                    // </>
                  )}
                </div>
              </div>
              {fineryResponse && view && (
                <div className="view-bank-box">
                  <div className="view-bank-head">
                    <h2>Addtional Details</h2>
                    <Button
                      className="action-btn link-btn item_center_x_5 "
                      onClick={() => {
                        setView(false);
                      }}
                    >
                      Hide Addtional Details
                    </Button>
                  </div>
                  <div className="view-bank-full-box">
                    <div className="view-bank-info-card">
                      <div className="view-bank-grid-layout">
                        <div className="view-box-card">
                          <h5 className="view-label">Id</h5>
                          <p className="view-value">{fineryResponse.data.id}</p>
                        </div>
                        <div className="view-box-card">
                          <h5 className="view-label">Client Order Id</h5>
                          <p className="view-value">
                            {fineryResponse.data.clientOrderId}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="view-bank-info-card">
                      <div className="view-bank-info-card-title">
                        <h3>Deals</h3>
                      </div>
                      <div className="view-bank-grid-layout">
                        <div className="view-box-card">
                          <h5 className="view-label">Size</h5>
                          <p className="view-value">
                            {fineryResponse.data.deals[0].size}
                          </p>
                        </div>
                        <div className="view-box-card">
                          <h5 className="view-label">Price</h5>
                          <p className="view-value">
                            {fineryResponse.data.deals[0].price}
                          </p>
                        </div>

                        <div className="view-box-card">
                          <h5 className="view-label">Price</h5>
                          <p className="view-value">
                            {fineryResponse.data.deals[0].volume}
                          </p>
                        </div>
                        <div className="view-box-card">
                          <h5 className="view-label">Counter Party Id</h5>
                          <p className="view-value">
                            {fineryResponse.data.deals[0].counterpartyId}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TradingTransactionModal;
